
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";

export default defineComponent({
  name: "modal-card",
  props: {
    image: String,
    modalId: String
  },
  components: {},
  data() {
    return {
      path: "",
      news_id: "",
      row: {}
    };
  },
  methods: {
    getNewsDetail() {
      const data = {
        news_id: this.news_id
      };
      ApiService.post("News/NewsDetail", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.row = data.data;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    initBus() {
      Bus.$on("handleBack", () => {
        this.$router.push({ path: this.path });
      });
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs("详情", ["新闻"]);
    setCurrentPageButton("back", true);
    this.initBus();
    this.path = this.$route.query.path as string;
    this.news_id = this.$route.query.news_id as string;
    this.getNewsDetail();
  }
});
