import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body p-0" }
const _hoisted_3 = { class: "card-px text-center py-10 my-10" }
const _hoisted_4 = { class: "fs-3 fw-bolder mb-5" }
const _hoisted_5 = { class: "text-gray-400 fs-5 fw-bold mb-2" }
const _hoisted_6 = { class: "card-px mb-10" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.row.news_title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.row.publish_time), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          innerHTML: _ctx.row.news_content
        }, null, 8, _hoisted_7)
      ])
    ])
  ]))
}